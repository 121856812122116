<template>

  <div>

    <milk-product-add-new
      :is-add-new-raw-milk-sidebar-active.sync="isAddNewRawMilkSidebarActive"
      @refetch-data-summary="refecthMilkProduct"
    />

    <!-- List produksi susu group by kambing -->
    <b-card
      no-body
      class="mb-0 mt-3"
    >

        <!-- Table Top -->
        <b-row class="pt-2 pl-2 pr-2">  
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <h4>Produk SmartFarm</h4>
          </b-col>

          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
          >
            
            <b-button
                variant="primary"
                @click="isAddNewRawMilkSidebarActive = true"
              >
                <span class="text-nowrap">+ Add Record</span>
              </b-button>
          </b-col>
        </b-row>
        <hr/>
        <b-row class="pl-2 pr-2 pb-2">
          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
          <b-form-input
          v-model="searchQueryMilkProduct"
          class="d-inline-block mr-1"
          placeholder="Search..."
        />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              
              
            </div>
          </b-col>
        </b-row>

      <b-table
        ref="refMilkProductTable"
        class="position-relative"
        :items="fetchMilkProducts"
        responsive
        :fields="tableColumnMilkProducts"
        primary-key="id"
        :sort-by.sync="sortByMilkProduct"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDescRawMilkSummary"
      >

        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <!-- <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.fullName)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              />
            </template> -->
            <b-link
              :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.fullName }}
            </b-link>
          </b-media>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-link :to="{ name: 'milkProductDetail', params: { id: data.item.id } }">
            <feather-icon icon="EyeIcon" />
          </b-link>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMetaRawMilkSummary.from }} to {{ dataMetaRawMilkSummary.to }} of {{ dataMetaRawMilkSummary.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <!-- <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPageRawMilkSummary"
              :total-rows="totalMilkProductList"
              :per-page="perPageRawMilkSummary"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col> -->

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useMilkProductsList from './useMilkProductsList'
import milkProductStoreModule from './milkProductStoreModule'

import MilkProductAddNew from './MilkProductAddNew.vue'

export default {
  components: {
    MilkProductAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  setup() {
    const MILK_PRODUCT_APP_STORE_MODULE_NAME = 'app-milk-products'

    // Register module
    if (!store.hasModule(MILK_PRODUCT_APP_STORE_MODULE_NAME)) store.registerModule(MILK_PRODUCT_APP_STORE_MODULE_NAME, milkProductStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MILK_PRODUCT_APP_STORE_MODULE_NAME)) store.unregisterModule(MILK_PRODUCT_APP_STORE_MODULE_NAME)
    })

    const isAddNewRawMilkSidebarActive = ref(false)

    const {
      fetchMilkProducts,
      tableColumnMilkProducts,
      perPageRawMilkSummary,
      currentPageRawMilkSummary,
      totalMilkProductList,
      dataMetaRawMilkSummary,
      perPageOptionsRawMilkSumarry,
      searchQueryMilkProduct,
      sortByMilkProduct,
      isSortDirDescRawMilkSummary,
      refMilkProductTable,

      refecthMilkProduct,
    } = useMilkProductsList()

    return {
      // Sidebar
      isAddNewRawMilkSidebarActive,

      // goatsRawMilkSummary
      fetchMilkProducts,
      tableColumnMilkProducts,
      perPageRawMilkSummary,
      currentPageRawMilkSummary,
      totalMilkProductList,
      dataMetaRawMilkSummary,
      perPageOptionsRawMilkSumarry,
      searchQueryMilkProduct,
      sortByMilkProduct,
      isSortDirDescRawMilkSummary,
      refMilkProductTable,

      refecthMilkProduct,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
