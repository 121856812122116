import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useRawMilksList() {
  // Use toast
  const toast = useToast()

  const refMilkProductTable = ref(null)

  // Table Handlers
  const tableColumnMilkProducts = [
    { key: 'nama_produk', sortable: false },
    { key: 'total_produksi', sortable: false },
    { key: 'total_stok_keluar', sortable: false },
    { key: 'stok_tersedia', sortable: false },
    { key: 'terakhir_diperbaruhi', sortable: false },
    { key: 'actions' },
  ]
  const perPageRawMilkSummary = ref(10)
  const totalMilkProductList = ref(0)
  const currentPageRawMilkSummary = ref(1)
  const perPageOptionsRawMilkSumarry = [10, 25, 50, 100]
  const searchQueryMilkProduct = ref('')
  const sortByMilkProduct = ref('id')
  const isSortDirDescRawMilkSummary = ref(true)

  const dataMetaRawMilkSummary = computed(() => {
    const localItemsCount = refMilkProductTable.value ? refMilkProductTable.value.localItems.length : 0
    return {
      from: perPageRawMilkSummary.value * (currentPageRawMilkSummary.value - 1) + (localItemsCount ? 1 : 0),
      to: perPageRawMilkSummary.value * (currentPageRawMilkSummary.value - 1) + localItemsCount,
      of: totalMilkProductList.value,
    }
  })

  const refecthMilkProduct = () => {
    refMilkProductTable.value.refresh()
  }

  watch([currentPageRawMilkSummary, perPageRawMilkSummary, searchQueryMilkProduct,], () => {
    refecthMilkProduct()
  })

  const mapServiceProductsToModel = ({id, category, unit, volume_in_total, volume_out_total, volume_remaining, last_updated}) => ({
    id,
    nama_produk: category,
    total_produksi: volume_in_total ?? null,
    total_stok_keluar: volume_out_total ?? null,
    satuan: unit ?? null,
    stok_tersedia: volume_remaining,
    terakhir_diperbaruhi: last_updated,
  });

  const fetchMilkProducts = (ctx, callback) => {
    store
      .dispatch('app-milk-products/fetchMilkProducts')
      .then(response => {
        const data = response.data.data.product
        const dataMapped = data.map(mapServiceProductsToModel);
        const { total } = response.data.data
        
        callback(dataMapped)
        totalMilkProductList.value = total
      })
      .catch((error) => {
        console.log(error);
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Goats Raw Milks Summary',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchMilkProducts,
    tableColumnMilkProducts,
    perPageRawMilkSummary,
    currentPageRawMilkSummary,
    totalMilkProductList,
    dataMetaRawMilkSummary,
    perPageOptionsRawMilkSumarry,
    searchQueryMilkProduct,
    sortByMilkProduct,
    isSortDirDescRawMilkSummary,
    refMilkProductTable,

    refecthMilkProduct,

  }
}
