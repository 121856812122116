<template>
  <b-sidebar
    id="add-new-raw-milk-sidebar"
    :visible="isAddNewRawMilkSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-raw-milk-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Catat Produksi / Penyaluran Produk {{ productName }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

        <!-- BODY -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

          <!-- Goat Mother -->
          <validation-provider
          #default="validationContext"
          name="Produk Susu"
          
        >
          <b-form-group
          :class= "isProductSelected ? 'd-block' : 'd-none'"
            :label="'Produk: ' + productName"
            label-for="product_name">
          </b-form-group>

          <b-form-group
           :class= "isProductSelected ? 'd-none' : 'd-block'"
            label="Pilih Produk Susu"
            label-for="product_category_id"
            :state="getValidationState(validationContext)"
          >
            <v-select
              v-model="productCatId.product_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="productOptions"
              :clearable="false"
              input-id="product_category_id"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

          <!-- Volume Susu Mentah -->
          <validation-provider
            #default="validationContext"
            name="Jumlah Masuk"
          >
            <b-form-group
              label="Volume Produksi Masuk"
              label-for="volume_in"
            >
              <b-form-input
                id="volume_in"
                v-model="product.volume_in"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Volume_in Produksi"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Volume Susu Mentah -->
          <validation-provider
            #default="validationContext"
            name="Jumlah Masuk"
          >
            <b-form-group
              label="Volume Produksi Keluar"
              label-for="volume_out"
            >
              <b-form-input
                id="volume_out"
                v-model="product.volume_out"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Jumlah volume keluar"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Tanggal Penggunaan -->
          <validation-provider
            #default="validationContext"
            name="Tanggal"
            rules="required"
          >
            <b-form-group
              label="Tanggal"
              label-for="date"
            >
              <b-form-datepicker
                id="date"
                placeholder="Choose a date"
                v-model="product.date"
                :state="getValidationState(validationContext)"
                trim
              />
              
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Deskripsi -->
          <validation-provider
          #default="validationContext"
          name="Deskripsi">

          <b-form-group
            label="Deskripsi"
            label-for="description"
            :state="getValidationState(validationContext)"
          >
            <b-form-input
              id="description"
              v-model="product.description"
              autofocus
              :state="getValidationState(validationContext)"
              trim
              placeholder="Deskripsi"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              v-if="$can('create', 'milk_product')"
            >
              Tambah
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Batal
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { BSidebar, BForm, BFormGroup, BFormInput,BFormRadioGroup, BFormInvalidFeedback, BButton, BFormDatepicker, BFormTimepicker } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import store from '@/store'
import router from '@/router'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BFormTimepicker,
    BFormInvalidFeedback,
    BButton,
    BFormRadioGroup,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewRawMilkSidebarActive',
    event: 'update:is-add-new-raw-milk-sidebar-active',
  },
  props: {
    isAddNewRawMilkSidebarActive: {
      type: Boolean,
      required: true,
    },
    isProductSelected: {
      type: Boolean,
      required: false,
    },
    productName: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      productOptions: [],
    }
  },
  setup(props, { emit }) {
    // use Toast
    const toast = useToast()
    // setup json post goat data
    const blankProductData = {
      production_category_id: null,
      volume_in: 0,
      volume_out: 0,
      date: '',
      description: null,
    }

    const blankProductSelected = {
      product_id: null,
    }
    const productCatId = ref( JSON.parse(JSON.stringify(blankProductSelected)));
    
    if(router.currentRoute.params.id !== null){
      blankProductData.production_category_id = router.currentRoute.params.id
    }


    //set default birth date is today
    const today = new Date();
    blankProductData.date = today;
    
    // define product from JSON data to ref variable
    const product = ref(JSON.parse(JSON.stringify(blankProductData)))

    // when click Cancle button
    const resetProductData = () => {
      product.value = JSON.parse(JSON.stringify(blankProductData))
      productCatId.value = JSON.parse(JSON.stringify(blankProductSelected));
    }

    // when click Add button
    const onSubmit = () => {
      if(!props.isProductSelected) {
        product.value.production_category_id = productCatId.value.product_id.value
      }
     
     if(product.value.description === null){
      product.value.description = '-'
     }

      store.dispatch('app-milk-products/addProduct', product.value)
      .then((response) => {
        
        if(response.data.data.status == 417){
          toast({
          component: ToastificationContent,
          props: {
            title: 'Gagal mencatat produksi produk SmartFarm',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        emit('update:is-add-new-raw-milk-sidebar-active', false)
        emit('refetch-data-summary')
        toast({
          component: ToastificationContent,
          props: {
            title: 'Berhasil mencatat hasil produk SmartFarm',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })

      })
  }

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetProductData)

    return {
      product,
      productCatId,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  created() {
    // get female goats
    this.$http.get('/milk/productions/categories/list').then(response => {
      const products = response.data.data

      products.forEach(product => {
        this.productOptions.push(
          {
            label: product.category + ' (' + product.unit + ')',
            value:  product.id
          }
        )
      });
    });
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';

#add-new-product-record-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
